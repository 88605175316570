<template>
    <div style="padding:20px">
        <h2>Privacy Policy</h2>
        <p>
            Welcome to [Website Name]! We are committed to protecting your privacy and ensuring that your personal information is used responsibly and securely. This Privacy Policy explains how we collect, use, share, and protect your personal information when you visit and use our website (the "Site"). By accessing or using the Site, you agree to be bound by this Privacy Policy.
        </p>
        <p>
            1. Collection of Personal Information
        </p>
        <p>
            We may collect personal information from you when you voluntarily provide it to us, such as when you create an account, make a purchase, subscribe to our newsletter, or participate in surveys, contests, or other promotional activities. The personal information we collect may include your name, email address, phone number, postal address, payment information, and other information you choose to provide.
        </p>
        <p>
            2. Use of Personal Information
        </p>
        <p>
            We use your personal information for the following purposes: <br />
            To provide you with the products, services, and information you request from us.<br />
            To communicate with you about your account, orders, and other matters related to your use of the Site.<br />
            To personalize your experience on the Site and tailor our offerings to your interests.<br />
            To improve the Site and our products and services.<br />
            To comply with legal obligations and enforce our Terms of Use.
        </p>
        <p>
            3. Sharing of Personal Information
        </p>
        <p>
            We do not share your personal information with third parties except as necessary to fulfill your requests, operate the Site, or as required by law. We may share your personal information with our service providers, such as payment processors, email service providers, and web hosting companies, who are contractually obligated to keep your information confidential and use it only for the purposes for which it is provided.
        </p>
        <p>
            We may also disclose your personal information if we believe in good faith that such disclosure is necessary to comply with the law, protect our rights or the rights of others, or prevent harm.
        </p>
        <p>
            4. Cookies and Other Tracking Technologies
        </p>
        <p>
            We may use cookies and other tracking technologies to collect information about your use of the Site. Cookies are small files that are stored on your computer or device when you visit a website. They allow us to recognize your device and provide you with a more personalized experience. You can choose to disable cookies in your browser settings, but this may affect the functionality of the Site.
        </p>
        <p>
            5. Security of Personal Information
        </p>
        <p>
            We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your personal information.
        </p>
        <p>
            6. Children's Privacy
        </p>
        <p>
            The Site is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we discover that a child under 13 has provided us with personal information, we will take steps to delete such information.
        </p>
        <p>
            7. Changes to the Privacy Policy
        </p>
        <p>
            We reserve the right to revise and update this Privacy Policy at any time. By continuing to access or use the Site after any revisions become effective, you agree to be bound by the revised Privacy Policy. It is your responsibility to regularly check this page for updates.
        </p>
        <p>
            8. Contact Us
        </p>
        <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at [indieingress@gmail.com].
        </p>
        <p>
            9. Effective Date
        </p>
        <p>
            This Privacy Policy is effective as of [Date].
        </p>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
h2{
    margin: 30px auto;
    text-align: center;
}
p{
    margin: 10px 0;
    font-size: 16px;
    padding: 0;
}
</style>